// extracted by mini-css-extract-plugin
var _1 = "wDhvk0rXZS";
var _2 = "xmhZRpmiVa";
var _3 = "f1BvXbFE4v";
var _4 = "oKiVowjUBZ";
var _5 = "aiZz93JUNJ";
var _6 = "E0bITlna2T";
var _7 = "ZL5a5Z6Skm";
var _8 = "LGAMGK6Sdx";
var _9 = "WDVkIKvR38";
var _a = "oyzuPOqGUt";
var _b = "t8DwdyOdBu";
export { _1 as "accuracy", _2 as "controls", _3 as "currentKey", _4 as "dailyGoal", _5 as "indicators", _6 as "keySet", _7 as "keyboard", _8 as "score", _9 as "speed", _a as "streakList", _b as "textInput" }
