// extracted by mini-css-extract-plugin
var _1 = "jRTd41lToD";
var _2 = "uJ0RmVgUF2";
var _3 = "zqzHfHFf8q";
var _4 = "HciBWswhaE";
var _5 = "ahUpey3G1J";
var _6 = "yonuzbdp2B";
var _7 = "ZdsHs1mtHf";
var _8 = "AyrGgoDFC_";
var _9 = "H26A4OBIOR";
var _a = "dCsDvxpqtV";
var _b = "uwBQqQ4Hzc";
var _c = "cnpzXn95u7";
var _d = "o1IrlVFfzL";
var _e = "gjP4d3Rcnk";
var _f = "kHDZay4U0s";
var _10 = "vL8HbshlQx";
var _11 = "nbQThuQmsq";
var _12 = "zFcrhj_4do";
var _13 = "aPlQZ5i66J";
var _14 = "yajsBzPFpf";
var _15 = "FDlxlUcRxo";
var _16 = "hBPP5frcS8";
var _17 = "qIjR5euU2_";
var _18 = "vwNkZdLHdK";
export { _1 as "bump", _2 as "button", _3 as "deadSymbol", _4 as "depressedKey", _5 as "fingerIndexLeft", _6 as "fingerIndexRight", _7 as "fingerMiddle", _8 as "fingerPinky", _9 as "fingerRing", _a as "fingerThumb", _b as "handLeft", _c as "handRight", _d as "key", _e as "ligatureSymbol", _f as "patternIndexLeft", _10 as "patternIndexRight", _11 as "patternMiddle", _12 as "patternPinky", _13 as "patternRing", _14 as "patternThumb", _15 as "primarySymbol", _16 as "secondarySymbol", _17 as "symbol", _18 as "toggledKey" }
