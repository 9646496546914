// extracted by mini-css-extract-plugin
var _1 = "NC44Rs0CTo";
var _2 = "pWYo9c1p3G";
var _3 = "X8blXgLP9h";
var _4 = "Bl5i8Guvoq";
var _5 = "iu6Z_P5Q89";
var _6 = "_LiPGXOtAF";
var _7 = "n26Ww1YCGs";
var _8 = "UZw_9tTo2O";
var _9 = "E1i4gY9dId";
var _a = "zJp9DCBlHg";
var _b = "jWUXD6TfLq";
var _c = "vCWXyPeDiD";
var _d = "p4mR9TJfXn";
var _e = "imKpBel9gF";
export { _1 as "accuracy", _2 as "complexity", _3 as "frame", _4 as "headerText", _5 as "hist_h", _6 as "hist_m", _7 as "hist_r", _8 as "keyFont", _9 as "lighterFrame", _a as "speed", _b as "subheaderText", _c as "threshold", _d as "value", _e as "valueFont" }
